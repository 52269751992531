export const AFI = require('./AFI.png');
export const CCBC = require('./CCBC.gif');
export const ConnectSociety = require('./ConnectSoeciety.jpg');
export const Convo = require('./Convo.jpg');
export const DBIYN = require('./DBIYN.png');
export const deafnation = require('./deafnation.jpg');
export const expo = require('./expo.jpg');
export const HKNC = require('./HKNC.jpg');
export const ICanConnect = require('./ICanConnect.png');
export const MDDORS = require('./MD DORS.png');
export const MDSchool = require('./MD School.jpg');
export const MDT = require('./MDT.jpg');
export const MTDP = require('./MT DP.png');
export const NCD = require('./NCD.png');
export const PPC = require('./PPC.png');
export const WDP = require('./WDP.png');
export const WIS = require('./WIS.png');
export const YANTERN = require('./Yantern.png');
export const AIRBNB = require('./airbnb.svg');