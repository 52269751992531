import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { HashLink as Link } from "react-router-hash-link";
require("dotenv").config();

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      let content = document.querySelector(".navbar-dropdown-content");
      content.style.display = "none";
    }
  }

  toggleDisplay() {
    let content = document.querySelector(".navbar-dropdown-content");
    console.log(content);
    if (content.style.display == "none" || content.style.display == "") {
      content.style.display = "block";
    } else {
      content.style.display = "none";
    }
  }

  handleKeyPress(event) {
    let charCode = typeof event.which == "number" ? event.which : event.keyCode;
    console.log(charCode);
    if (event.key == "Enter" || charCode == 32) {
      event.preventDefault();
      this.toggleDisplay();
    }
  }

  handleScroll() {
    let navbar = document.getElementById("navbar");
    let home = document.querySelector(".navbar-home");
    let mizaruTab = document.querySelector(".navbar-mizaru");
    let channel = document.querySelector(".navbar-channel");
    let dropdown = document.querySelector(".navbar-dropdown-content");

    if (home && navbar && mizaruTab && dropdown && channel) {
      let scrolled = window.pageYOffset > 0;
      home.classList.toggle("scrolled-text", scrolled);
      navbar.classList.toggle("scrolled", scrolled);
      dropdown.classList.toggle("scrolled", scrolled);
      mizaruTab.classList.toggle("scrolled-text", scrolled);
      channel.classList.toggle("scrolled-text", scrolled);
    }
  }

  render() {
    return (
      <nav className="navbar" id="navbar">
        <div className="skiptocontent">
          <a href="#aboutus">skip to main content</a>
        </div>
        <Link to="/#home" aria-label="Home">
          <div className="navbar-home">Home</div>
        </Link>
        <span className="navbar-divider" aria-hidden="true">
          |
        </span>
        <a
          href={process.env.REACT_APP_MIZARU_LINK}
          target="_blank"
          rel="Mizaru App"
          aria-label="Mizaru"
          tabIndex={0}
        >
          <div className="navbar-mizaru">Mizaru</div>
        </a>
        <span className="navbar-divider" aria-hidden="true">
          |
        </span>
        <a
          className="navbar-channel"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCaHymjlliFHCFG3qkD4blng"
        >
          {" "}
          Iwazaru Channel{" "}
        </a>
        <span className="navbar-divider" aria-hidden="true">
          |
        </span>
        <div className="navbar-dropdown" ref={this.setWrapperRef}>
          <div
            className="navbar-dropdown-hamburger"
            tabIndex="0"
            onClick={this.toggleDisplay}
            onKeyPress={this.handleKeyPress}
            aria-label="Press space key or enter key to open the menu"
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
          <div
            className="navbar-dropdown-content"
            aria-labelledby="menu dropdown"
          >
            <Link
              to="/#home"
              className="collapse"
              onClick={this.toggleDisplay}
              onKeyPress={this.handleKeyPress}
            >
              Home
            </Link>
            {/* <Link to="/mizaru" className="collapse"> */}
            <a
              className="collapse"
              href={process.env.REACT_APP_MIZARU_LINK}
              target="_blank"
              rel="Mizaru App"
              onClick={this.toggleDisplay}
              onKeyPress={this.handleKeyPress}
            >
              My Mizaru
            </a>
            {/* </Link> */}
            <a
              className="collapse"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCaHymjlliFHCFG3qkD4blng"
              onClick={this.toggleDisplay}
              onKeyPress={this.handleKeyPress}
            >
              Iwazaru Channel
            </a>
            <a
              href="https://www.3mcomm.com/#aboutus"
              onClick={this.toggleDisplay}
              onKeyPress={this.handleKeyPress}
            >
              About Us
            </a>
            <a
              href="https://www.3mcomm.com/#services"
              onClick={this.toggleDisplay}
              onKeyPress={this.handleKeyPress}
            >
              Services
            </a>
            <a
              href="https://www.3mcomm.com/#team"
              onClick={this.toggleDisplay}
              onKeyPress={this.handleKeyPress}
            >
              Meet Our Team
            </a>
            {/*<a href="#partner">Spotlight</a>*/}
            <a
              href="https://www.3mcomm.com/#contactus"
              onClick={this.toggleDisplay}
              onKeyPress={this.handleKeyPress}
            >
              Contact Us
            </a>
          </div>
        </div>
      </nav>
    );
  }
}
