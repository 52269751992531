import React, { Component } from 'react';
import Navbar from './components/Navbar'
import Header from './components/Header'
import Intro from './components/Intro'
import Service from './components/Service'
import Team from './components/Team'
import Footer from './components/Footer'
import Partnership from './components/Partnership'
import Contact from './components/Contact'
import TopButton from './components/TopButton'
// import SafariReminder from './components/SafariReminder';
import Privacy from './components/Privacy'
import Terms from './components/Terms'
import { Switch, Route } from "react-router-dom";

class Homepage extends Component {
    state = {
        isSafari: false,
        visited: false
    }

    componentDidMount() {
        this.observeMutationChrome()
        window.addEventListener("hashchange", function () {
            window.scrollTo(window.scrollX, window.scrollY - 100);
        })
    };

    observeMutationChrome = () => {
        // Returns the Element that is the root element of the docuemnt, e.g. the <html>
        const targetNode = document.documentElement;
        const config = { attributes: true };

        // Callback function to execute when mutations are observed
        const callback = (mutationsList, observer) => {
            // Use traditional 'for loops' for IE 11
            // There will be two items in mutation list:
            // one has the attributeName: 'hc', another one: 'hcx'
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === "hc") {
                    let attributes = mutation.target.attributes.getNamedItem(mutation.attributeName)
                    if (attributes != null && attributes.value !== "a0") {
                        console.log("High Contrast On: ", attributes.value);
                        this.invertColorChrome()
                    }
                    else {
                        console.log("High Contrast Off");
                        this.invertColorChrome()
                    }
                }
            }
        }
        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }

    invertColorChrome = () => {
        let header = document.querySelector('.header')
        let navbar = document.querySelector('.navbar')
        
        if (header) {
            let htmlTag = document.getElementsByTagName('html');
            let isInverted = htmlTag[0].getAttribute('hc') != null
            header.classList.toggle('inverted', isInverted)
        }
        if (navbar) {
            let htmlTag = document.getElementsByTagName('html');
            let isInverted = htmlTag[0].getAttribute('hc') != null
            navbar.classList.toggle('inverted', isInverted)
        }
    }

    render() {
        return (
            <div>
                <Navbar />
                {/* <p> This is a test </p> */}
                <Header />
                <Switch>
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/terms_of_use" component={Terms} />
                    <Route path="/">
                        <Intro />
                        <Service />
                        <Team />
                        {/*<Partnership />*/}
                        <Contact />
                    </Route>
                </Switch>
                <Footer />
                <TopButton />
                {/* {!this.state.visited && this.state.isSafari && <SafariReminder dismissReminder={this.dismissReminder} />} */}
            </div>
        );
    }
}


export default Homepage;