import React, { Component } from 'react'
import { AFI, CCBC, ConnectSociety, Convo, DBIYN, deafnation, expo, HKNC, ICanConnect, MDDORS, MDSchool, MDT, MTDP, NCD, PPC, WDP, WIS, YANTERN, AIRBNB } from '../images/partner'
const partners = {
    0: { 'title': 'Airbnb', 'img': AIRBNB },
    1: { 'title': 'American Family Insurnce', 'img': AFI },
    2: { 'title': 'CCBC', 'img': CCBC },
    3: { 'title': 'Connect Society', 'img': ConnectSociety },
    4: { 'title': 'Convo', 'img': Convo },
    5: { 'title': 'Deafblind International Youth Network', 'img': DBIYN },
    6: { 'title': 'Deaf Nation', 'img': deafnation },
    7: { 'title': 'Mata Expo', 'img': expo },
    8: { 'title': 'HKNC', 'img': HKNC },
    10: { 'title': 'iCanConnect', 'img': ICanConnect },
    11: { 'title': 'MD DORS', 'img': MDDORS },
    12: { 'title': 'MD School for the Blind', 'img': MDSchool },
    13: { 'title': 'Dept of MD Transportation', 'img': MDT },
    14: { 'title': 'MT Deafblind Project', 'img': MTDP },
    15: { 'title': 'National Center on Deafblind', 'img': NCD },
    16: { 'title': 'Peak Parent Center', 'img': PPC },
    17: { 'title': 'Wisconsin Deafblind Project', 'img': WDP },
    18: { 'title': 'World In Sign', 'img': WIS },
    19: { 'title': 'Yantern', 'img': YANTERN }
}

export default class Partnership extends Component {
    state = {
        showMore: true,
        displayingItem: 10
    }

    toggleShowMore() {
        this.setState({ showMore: false, displayingItem: partners.length })
    }

    render() {
        return (
            <div className="container partner section-padding">
                <h2 className="partner-title title1">
                    <a name="partner" tabIndex="0">Spotlight</a>
                </h2>

                <div className="partner-list">
                    {
                        Object.values(partners).slice(0, this.state.displayingItem).map((partner, index) =>
                            <div key={index} className="partner-wrapper">
                                <img src={partner['img']} alt={partner['title'] + ' logo'} className="partner-logo" tabIndex="0"/>
                                <div className="partner-name" tabIndex="0">{partner['title']}</div>
                            </div>
                        )
                    }
                    {this.state.showMore &&
                        <div>
                            <button className="partner-list-showmore" onClick={() => this.toggleShowMore()}>Show More</button>
                        </div>
                    }
                </div>

            </div>
        )
    }
}
