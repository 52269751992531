import React, { Component } from "react";
import dotenv from "dotenv";
// import Recaptcha from 'react-recaptcha'
import { ReCaptcha } from "react-recaptcha-v3";
import { load } from "recaptcha-v3";
require("dotenv").config();

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      email: "",
      subject: "",
      comment: "",
      isHalfFilled: false,
    };

    this.change = this.change.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.checkName = this.checkName.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.checkSubject = this.checkSubject.bind(this);
    this.checkComment = this.checkComment.bind(this);
    this.postData = this.postData.bind(this);
    this.checkEmpty = this.checkEmpty.bind(this);
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.checkEmpty();
  };

  checkRecaptcha = async () => {
    const token = await this.loadRecaptcha();
    console.log("2. From check recaptcha function", token);
    let google_response = await this.verifyRecaptcha(token);
    console.log("response", google_response);
    if (google_response.result === "Human") {
      return true;
    } else {
      return false;
    }
  };

  loadRecaptcha = async () => {
    const recaptcha = await load(process.env.REACT_APP_RECAPTCHASITEKEY);
    const token = await recaptcha.execute("submit");
    console.log("1. From load recaprcha function", token); // Will also print the token
    return token;
  };

  verifyRecaptcha = async (token) => {
    console.log("3. From verify recaprcha function", token);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_VERIFYCAPTCHA}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          from: "Marketing Site",
        }),
      }
    );
    const json = await response.json();
    return json;
  };

  checkStatus = async () => {
    let nameValid = this.checkName();
    let emailValid = this.checkEmail();
    let subjectValid = this.checkSubject();
    let commentValid = this.checkComment();
    let recaptcha = await this.checkRecaptcha();
    if (nameValid && emailValid && subjectValid && commentValid) {
      if (recaptcha === true) {
        this.postData();
      }
      // const token = await this.loadRecaptcha();
      // let google_response = await this.verifyRecaptcha(token);
      // console.log(google_response);
      // console.log(google_response.google_response.score)
      // if (response.ok && google_response.ok) {
    } else {
    }
  };

  postData = async () => {
    let responseMsgSuccess = document.getElementById("responseMsgSuccess");
    await fetch(`${process.env.REACT_APP_BACKEND_CONTACTUS}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        contactFrom: "Contact Us from 3MC Marketing Site",
        userName: this.state.userName,
        email: this.state.email,
        subject: this.state.subject,
        comment: this.state.comment,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.isIssueCreated) {
          this.setState({
            userName: "",
            email: "",
            subject: "",
            comment: "",
          });
          responseMsgSuccess.style =
            "display: inline; color: #003f00; font-size: 15px";
          responseMsgSuccess.innerHTML =
            "Your message has been sent to us successfully. We will be in touch with you soon!";
          return true;
        } else {
          responseMsgSuccess.style = "display: none";
          console.log("runing");
          return false;
        }
      });
  };

  checkName = () => {
    let nameInput = document.getElementById("name");
    let responseMsgName = document.getElementById("responseMsgName");

    if (nameInput.value.length == 0) {
      responseMsgName.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgName.innerHTML = "Please provide a name";
      return false;
    } else {
      responseMsgName.style = "display: none;";
      return true;
    }
  };

  checkEmail = () => {
    let emailInput = document.getElementById("email");
    let responseMsgEmail = document.getElementById("responseMsgEmail");
    const rex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailInput.value.length == 0) {
      responseMsgEmail.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgEmail.innerHTML = "Please provide an email";
      return false;
    } else if (!rex.test(String(emailInput.value).toLowerCase())) {
      responseMsgEmail.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgEmail.innerHTML = "Please provide an correct email address";
      return false;
    } else {
      responseMsgEmail.style = "display: none;";
      return true;
    }
  };

  checkSubject = () => {
    let subjectInput = document.getElementById("subject");
    let responseMsgSubject = document.getElementById("responseMsgSubject");

    if (subjectInput.value.length == 0) {
      responseMsgSubject.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgSubject.innerHTML = "Please provide a subject";
      return false;
    } else {
      responseMsgSubject.style = "display: none;";
      return true;
    }
  };

  checkComment = () => {
    let commentInput = document.getElementById("comment");
    let responseMsgComment = document.getElementById("responseMsgComment");

    if (commentInput.value.length == 0) {
      responseMsgComment.style =
        "display: inline; color: #840011; font-size: 15px";
      responseMsgComment.innerHTML = "Please provide some comments";
      return false;
    } else {
      responseMsgComment.style = "display: none";
      return true;
    }
  };

  checkEmpty = () => {
    let nameInput = document.getElementById("name");
    let emailInput = document.getElementById("email");
    let subjectInput = document.getElementById("subject");
    let commentInput = document.getElementById("comment");
    if (
      nameInput.value.length > 0 ||
      emailInput.value.length > 0 ||
      subjectInput.value.length > 0 ||
      commentInput.value.length > 0
    ) {
      this.setState({ isHalfFilled: true });
    }
  };

  render() {
    return (
      <div className="container-fluid contact sectionPadding">
        <div className="container">
          <h2 className="title1">
            <a name="contactus" tabIndex="0">
              Contact Us
            </a>
          </h2>
          <div className="row">
            <label htmlFor="name" className="col-5 contact-label">
              <span className="contact-label-required">(REQUIRED)</span>Name
              &nbsp;
            </label>
            <div className="col-7 contact-input">
              <input
                id="name"
                placeholder="Please enter your name"
                name="userName"
                value={this.state.userName}
                onChange={this.change}
              />
              <div>
                <span className="ml-2" id="responseMsgName" />
              </div>
            </div>
          </div>
          <div className="row">
            <label htmlFor="email" className="col-5 contact-label">
              <span className="contact-label-required">(REQUIRED)</span>Email
              &nbsp;
            </label>
            <div className="col-7 contact-input">
              <input
                id="email"
                placeholder="Please enter your email"
                name="email"
                value={this.state.email}
                onChange={this.change}
              />
              <div>
                <span className="ml-2" id="responseMsgEmail" />
              </div>
            </div>
          </div>
          <div className="row">
            <label htmlFor="subject" className="col-5 contact-label">
              <span className="contact-label-required">(REQUIRED)</span>Subject
              &nbsp;
            </label>
            <div className="col-7 contact-input">
              <input
                id="subject"
                placeholder="Please enter your subject"
                name="subject"
                value={this.state.subject}
                onChange={this.change}
              />
              <div>
                <span className="ml-2" id="responseMsgSubject" />
              </div>
            </div>
          </div>
          <div className="row">
            <label htmlFor="comment" className="col-5 contact-label">
              <span className="contact-label-required">(REQUIRED)</span>Comments
              &nbsp;
            </label>
            <div className="col-7 contact-input">
              <textarea
                id="comment"
                placeholder="Please enter your comments"
                name="comment"
                value={this.state.comment}
                onChange={this.change}
              />
              <div>
                <span className="ml-2" id="responseMsgComment" />
              </div>
            </div>
          </div>
          <div className="contact-submit">
            <button
              type="button"
              className="contact-button"
              onClick={this.checkStatus}
            >
              Submit
            </button>
          </div>
          <span className="mt-3" id="responseMsgSuccess" />
        </div>
      </div>
    );
  }
}
