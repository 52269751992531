import React, { Component } from 'react'
import monkeylogo from '../images/3mc_logo.png'

export default class Header extends Component {
    render() {
        return (
            <header className="header" id="home">
                <div className="header-logo">
                    <img src={monkeylogo} alt="3mc-logo" id="header-logo" />
                </div>
                <h1 className="header-title" tabIndex="0">THREE MONKEYS COMMUNICATION</h1>
                <div role="heading" aria-level="2" className="header-slogan" tabIndex="0">SEE IT, FEEL IT, HEAR IT</div>
            </header>
        )
    }
}
