import React, { Component } from 'react'

export default class TopButton extends Component {

    // When the user scrolls down 20px from the top of the document, show the button
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        let mybutton = document.getElementById("topbutton");
        if (mybutton) {
            if (window.pageYOffset > 100) {
                mybutton.style.display = "flex";
            } else {
                mybutton.style.display = "none";
            }
        }
    }

    goTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <div id="topbutton" className="topbutton-wrapper" onClick={() => this.goTop()}>
                <div className="topbutton"><i className="fa fa-angle-up"></i></div>
            </div>
        )
    }
}
