import React, { Component } from 'react';
import './App.css';
import Homepage from './Homepage'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="App" >
        <Router>
          <Switch>
            {/* <Route path="/mizaru" component={Mizaru} />  */}
            <Route path="/"><Homepage /></Route> 
            {/* <Route path="/"><Login /></Route> */}
            {/* <Route path="/" component={Mizaru} />  */}
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
