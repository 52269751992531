import React, { Component } from "react";
import Video from "../Youtube/Video";
import Transcripts from "../Youtube/Transcripts";
import PrivacyPolicy from "../PrivacyPolicy";

class Privacy extends Component {
  render() {
    return (
      <div className="container privacy">
        <div className="title3 privacy-title" id="privacy-title" tabIndex="0">
          Privacy Policy
        </div>
        <div className="privacy-wrapper">
          <div className="privacy-video">
            <Video id="XEVusRSjZEc" title="3mc privacy policy video" />
            <div className="privacy-video-text">
              <Transcripts id="XEVusRSjZEc" />
            </div>
          </div>
          <div className="privacy-content" tabIndex="0">
            <PrivacyPolicy />
          </div>
        </div>
        <div className="go-back">
          <a href="/">
            <button>Back</button>
          </a>
        </div>
      </div>
    );
  }
}

export default Privacy;
