import React, { Component } from 'react'

export default class SeeMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            data: this.props.text
        }
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    toggleKey = event => {
        let charCode = typeof event.which == "number" ? event.which : event.keyCode;
        if (event.key == "Enter" || charCode == 32) {
          event.preventDefault();
          this.toggle();
        }
    }

    getRenderedItems() {
        if (this.state.isOpen) {
            let renderItems = []
            for (var i of this.state.data) {
                renderItems.push(i)
            }
            return renderItems
        }
        return this.state.data[0].slice(0, 250) + '    ......  ';
    }

    render() {
        return (
            <div tabIndex="0">{this.getRenderedItems()}
                <a className='see-more' onClick={this.toggle} tabIndex="0" onKeyUp={this.toggleKey}>
                    {this.state.isOpen
                        ? '    See Less'
                        : 'See More'
                    }
                </a>
            </div>
        );
    }
}