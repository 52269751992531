import React, { Component } from "react";
require("dotenv").config();

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="contentWrapper">
        <div className="normal initialText">
          Protecting your private information is our priority. This Statement of
          Privacy applies to&nbsp;
          <a href="https://www.3mcomm.com">https://www.3mcomm.com</a>
          &nbsp;and Three Monkeys Communication LLC and governs data collection
          and usage. For the purposes of this Privacy Policy, unless otherwise
          noted, all references to Three Monkeys Communication LLC include&nbsp;
          <a href="https://www.3mcomm.com">https://www.3mcomm.com</a>
          &nbsp;and 3MC. By using the 3MC website, you consent to the data
          practices described in this statement.
        </div>

        <div>
          <div className="title2">Collection of your Personal Information</div>
          <div className="normal">
            In order to better provide you with products and services offered on
            our Site, 3MC may collect personally identifiable information, such
            as your:
            <ul>
              <li>First and Last Name</li>
              <li>E-mail Address</li>
            </ul>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services available on the Site. These may
            include: (a) registering for an account on our Site; (b) entering a
            sweepstakes or contest sponsored by us or one of our partners; (c)
            signing up for special offers from selected third parties; (d)
            sending us an email message; (e) submitting your credit card or
            other payment information when ordering and purchasing products and
            services on our Site. To wit, we will use your information for, but
            not limited to, communicating with you in relation to services
            and/or products you have requested from us. We also may gather
            additional personal or non-personal information in the future.
          </div>
          <div className="title2">Use of your Personal Information</div>
          <div className="normal">
            3MC collects and uses your personal information to operate its
            website(s) and deliver the services you have requested.
          </div>
          <div className="normal">
            3MC may also use your personally identifiable information to inform
            you of other products or services available from 3MC and its
            affiliates.
          </div>
          <div className="title2">Sharing Information with Third Parties</div>

          <div className="normal">
            3MC does not sell, rent or lease its customer lists to third
            parties.
          </div>

          <div className="normal">
            3MC may, from time to time, contact you on behalf of external
            business partners about a particular offering that may be of
            interest to you. In those cases, your unique personally identifiable
            information (e-mail, name, address, telephone number) is not
            transferred to the third party. 3MC may share data with trusted
            partners to help perform statistical analysis, send you email or
            postal mail, provide customer support, or arrange for deliveries.
            All such third parties are prohibited from using your personal
            information except to provide these services to 3MC, and they are
            required to maintain the confidentiality of your information.
          </div>

          <div className="normal">
            3MC may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on 3MC or the site; (b) protect and
            defend the rights or property of 3MC; and/or (c) act under exigent
            circumstances to protect the personal safety of users of 3MC, or the
            public.
          </div>

          <div className="title2">Tracking User Behavior</div>

          <div className="normal">
            3MC may keep track of the websites and pages our users visit within
            3MC, in order to determine what 3MC services are the most popular.
            This data is used to deliver customized content and advertising
            within 3MC to customers whose behavior indicates that they are
            interested in a particular subject area.
          </div>

          <div className="title2">Automatically Collected Information</div>

          <div className="normal">
            Information about your computer hardware and software may be
            automatically collected by 3MC. This information can include: your
            IP address, browser type, domain names, access times and referring
            website addresses. This information is used for the operation of the
            service, to maintain quality of the service, and to provide general
            statistics regarding use of the 3MC website.
          </div>

          <div className="title2">Use of Cookies</div>

          <div className="normal">
            The 3MC website may use "cookies" to help you personalize your
            online experience. A cookie is a text file that is placed on your
            hard disk by a web page server. Cookies cannot be used to run
            programs or deliver viruses to your computer. Cookies are uniquely
            assigned to you, and can only be read by a web server in the domain
            that issued the cookie to you.
          </div>

          <div className="normal">
            One of the primary purposes of cookies is to provide a convenience
            feature to save you time. The purpose of a cookie is to tell the Web
            server that you have returned to a specific page. For example, if
            you personalize 3MC pages, or register with 3MC site or services, a
            cookie helps 3MC to recall your specific information on subsequent
            visits. This simplifies the process of recording your personal
            information, such as billing addresses, shipping addresses, and so
            on. When you return to the same 3MC website, the information you
            previously provided can be retrieved, so you can easily use the 3MC
            features that you customized.
          </div>

          <div className="normal">
            You have the ability to accept or decline cookies. Most Web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. If you choose to
            decline cookies, you may not be able to fully experience the
            interactive features of the 3MC services or websites you visit.
          </div>

          <div className="title2">Links</div>

          <div className="normal">
            This website contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when theyleave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </div>

          <div className="title2">Security of your Personal Information</div>

          <div className="normal">
            3MC secures your personal information from unauthorized access, use,
            or disclosure. 3MC uses the following methods for this purpose:
            <ul>
              <li>SSL Protocol</li>
            </ul>
            When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of
            encryption, such as the Secure Sockets Layer (SSL) protocol.
          </div>

          <div className="normal">
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data
            exchanged between you and us through this Site cannot be guaranteed.
          </div>

          <div className="title2">Right to Deletion</div>

          <div className="normal">
            Subject to certain exceptions set out below, on receipt of a
            verifiable request from you, we will:
            <ul>
              <li>Delete your personal information from our records; and</li>
              <li>
                Direct any service providers to delete your personal information
                from their records.
              </li>
              <li>
                Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to
              </li>
              <li>
                Complete the transaction for which the personal information was
                collected, fulfill the terms of a written warranty or product
                recall conducted in accordance with federal law, provide a good
                or service requested by you, or reasonably anticipated within
                the context of our ongoing business relationship with you, or
                otherwise perform a contract between you and us;
              </li>
              <li>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity; or prosecute those responsible
                for that activity;
              </li>
              <li>
                Debug to identify and repair errors that impair existing
                intended functionality;
              </li>
              <li>
                Exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech, or exercise another
                right provided for by law; •Comply with the California
                Electronic Communications Privacy Act;
              </li>
              <li>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when our deletion of
                the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed consent;
              </li>
              <li>
                Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with us;{" "}
              </li>
              <li>Comply with an existing legal obligation; or </li>
              <li>
                Otherwise use your personal information, internally, in a lawful
                manner that is compatible with the context in which you provided
                the information.
              </li>
            </ul>
          </div>

          <div className="title2">Children Under Thirteen</div>

          <div className="normal">
            3MC does not knowingly collect personally identifiable information
            from children under the age of thirteen. If you are under the age of
            thirteen, you must ask your parent or guardian for permission to use
            this website.
          </div>

          <div className="title2">E-mail Communications</div>

          <div className="normal">
            From time to time, 3MC may contact you via email for the purpose of
            providing announcements, promotional offers, alerts, confirmations,
            surveys, and/or other general communication.
          </div>

          <div className="title2">Changes to this Statement</div>

          <div className="normal">
            3MC reserves the right to change this Privacy Policy from time to
            time. We will notify you about significant changes in the way we
            treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on
            our site, and/or by updating any privacy information on this page.
            Your continued use of the Site and/or Services available through
            this Site after such modifications will constitute your: (a)
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.
          </div>

          <div className="title2">Contact Information</div>

          <div className="normal">
            3MC welcomes your questions or comments regarding this Statement of
            Privacy. If you believe that 3MC has not adhered to this Statement,
            please contact 3MC at:
          </div>
          <div className="normal">Three Monkeys Communication LLC</div>
          <div className="normal">
            Email Address:{" "}
            {/* <button type="button" className="link-button">
              support@3mcomm.com
            </button> */}
            <a href="mailto: support@3mcomm.com" target="_blank">
              support@3mcomm.com
            </a>
          </div>
          {/* <br /> */}
          {/* <div className="normal">Telephone number: 240-241-7317</div> */}
          <div className="normal">Effective as of March 22, 2021</div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
