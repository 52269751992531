import React, { Component } from "react";
import Video from "../Youtube/Video";
import Transcripts from "../Youtube/Transcripts";
import TermsofUse from "../TermsOfUse";
class Terms extends Component {
  render() {
    return (
      <div className="container terms">
        <div className="title3 terms-title" id="terms-title" tabIndex="0">
          Terms of Use
        </div>
        <div className="content-wrapper">
          <div className="terms-video">
            <Video id="WBwotZCWmi4" title="3mc mizaru terms video" />
            <div className="terms-video-text">
              <Transcripts id="WBwotZCWmi4" />
            </div>
          </div>
          <div className="terms-content" tabIndex="0">
            <TermsofUse />
          </div>
        </div>
        <div className="go-back">
          <a href="/">
            <button>Back</button>
          </a>
        </div>
      </div>
    );
  }
}

export default Terms;
