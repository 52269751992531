import React, { Component } from "react";

class TermsofUse extends Component {
  render() {
    return (
      <div className="contentWrapper">
        <div className="initialText">
          <div className="title2">
            Agreement between User and
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>
          </div>
          <div className="normal">
            Welcome to
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            The
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            website (the "Site") is comprised of various web pages operated by
            Three Monkeys Communication LLC ("3MC").
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            is offered to you conditioned on your acceptance without
            modification of the terms, conditions, and notices contained herein
            (the "Terms"). Your use of
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            constitutes your agreement to all such Terms. Please read these
            terms carefully, and keep a copy of them for your reference.
          </div>
        </div>

        <div className="terms-content-text">
          {/* <div className="normal">
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            is an E-Commerce Site.
          </div> */}
          <div className="normal">
            This website provides information regarding services and products
            3MC offers. 3MC's services include keynote presentations, corporate
            training, personal coaching, and accessibility consulting. 3MC's
            products include the Mizaru web application and Iwazaru Channel.
          </div>
          <div className="title2">Privacy</div>
          <div className="normal">
            Your use of
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>
            is subject to 3MC's Privacy Policy. Please review our Privacy
            Policy, which also governs the Site and informs users of our data
            collection practices.
          </div>
          <div className="title2">Electronic Communications</div>
          <div className="normal">
            Visiting
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>
            or sending emails to 3MC constitutes electronic communications. You
            consent to receive electronic communications and you agree that all
            agreements, notices, disclosures and other communications that we
            provide to you electronically, via email and on the Site, satisfy
            any legal requirement that such communications be in writing.
          </div>
          <div className="title2">Children Under Thirteen</div>

          <div className="normal">
            3MC does not knowingly collect, either online or offline, personal
            information from persons under the age of thirteen. If you are under
            18, you may use{" "}
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            only with permission of a parent or guardian.{" "}
          </div>

          <div className="title2">
            Links to Third Party Sites/Third Party Services
          </div>

          <div className="normal">
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            may contain links to other websites ("Linked Sites"). The Linked
            Sites are not under the control of 3MC and 3MC is not responsible
            for the contents of any Linked Site, including without limitation
            any link contained in a Linked Site, or any changes or updates to a
            Linked Site. 3MC is providing these links to you only as a
            convenience, and the inclusion of any link does not imply
            endorsement by 3MC of the site or any association with its
            operators.
          </div>

          <div className="normal">
            Certain services made available via{" "}
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            are delivered by third party sites and organizations. By using any
            product, service or functionality originating from the{" "}
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            domain, you hereby acknowledge and consent that 3MC may share such
            information and data with any third party with whom 3MC has a
            contractual relationship to provide the requested product, service
            or functionality on behalf of{" "}
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            users and customers.
          </div>
          <div className="title2">
            No Unlawful or Prohibited Use/Intellectual Property
          </div>
          <div className="normal">
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use{" "}
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            strictly in accordance with these terms of use. As a condition of
            your use of the Site, you warrant to 3MC that you will not use the
            Site for any purpose that is unlawful or prohibited by these Terms.
            You may not use the Site in any manner which could damage, disable,
            overburden, or impair the Site or interfere with any other party's
            use and enjoyment of the Site. You may not obtain or attempt to
            obtain any materials or information through any means not
            intentionally made available or provided for through the Site.
          </div>

          <div className="normal">
            All content included as part of the Service, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the Site, is the property of 3MC or its suppliers and
            protected by copyright and other laws that protect intellectual
            property and proprietary rights. You agree to observe and abide by
            all copyright and other proprietary notices, legends or other
            restrictions contained in any such content and will not make any
            changes thereto.{" "}
          </div>

          <div className="normal">
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. 3MC content is not for resale. Your use of the Site does
            not entitle you to make any unauthorized use of any protected
            content, and in particular you will not delete or alter any
            proprietary rights or attribution notices in any content. You will
            use protected content solely for your personal use, and will make no
            other use of the content without the express written permission of
            3MC and the copyright owner. You agree that you do not acquire any
            ownership rights in any protected content. We do not grant you any
            licenses, express or implied, to the intellectual property of 3MC or
            our licensors except as expressly authorized by these Terms.
          </div>

          <div className="title2">Third Party Accounts</div>

          <div className="normal">
            You will be able to connect your 3MC account to third party
            accounts. By connecting your 3MC account to your third party
            account, you acknowledge and agree that you are consenting to the
            continuous release of information about you to others (in accordance
            with your privacy settings on those third party sites). If you do
            not want information about you to be shared in this manner, do not
            use this feature.
          </div>

          <div className="title2">International Users</div>

          <div className="normal">
            The Service is controlled, operated and administered by 3MC from our
            offices within the USA. If you access the Service from a location
            outside the USA, you are responsible for compliance with all local
            laws. You agree that you will not use the 3MC Content accessed
            through{" "}
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            in any country or in any manner prohibited by any applicable laws,
            restrictions or regulations.
          </div>

          <div className="title2">Indemnification</div>

          <div className="normal">
            You agree to indemnify, defend and hold harmless 3MC, its officers,
            directors, employees, agents and third parties, for any losses,
            costs, liabilities and expenses (including reasonable attorney's
            fees) relating to or arising out of your use of or inability to use
            the Site or services, any user postings made by you, your violation
            of any terms of this Agreement or your violation of any rights of a
            third party, or your violation of any applicable laws, rules or
            regulations. 3MC reserves the right, at its own cost, to assume the
            exclusive defense and control of any matter otherwise subject to
            indemnification by you, in which event you will fully cooperate with
            3MC in asserting any available defenses.
          </div>

          <div className="title2">Arbitration</div>

          <div className="normal">
            In the event the parties are not able to resolve any dispute between
            them arising out of or concerning these Terms and Conditions, or any
            provisions hereof, whether in contract, tort, or otherwise at law or
            in equity for damages or any other relief, then such dispute shall
            be resolved only by final and binding arbitration pursuant to the
            Federal Arbitration Act, conducted by a single neutral arbitrator
            and administered by the American Arbitration Association, or a
            similar arbitration service selected by the parties, in a location
            mutually agreed upon by the parties. The arbitrator's award shall be
            final, and judgment may be entered upon it in any court having
            jurisdiction. In the event that any legal or equitable action,
            proceeding or arbitration arises out of or concerns these Terms and
            Conditions, the prevailing party shall be entitled to recover its
            costs and reasonable attorney's fees. The parties agree to arbitrate
            all disputes and claims in regards to these Terms and Conditions or
            any disputes arising as a result of these Terms and Conditions,
            whether directly or indirectly, including Tort claims that are a
            result of these Terms and Conditions. The parties agree that the
            Federal Arbitration Act governs the interpretation and enforcement
            of this provision. The entire dispute, including the scope and
            enforceability of this arbitration provision shall be determined by
            the Arbitrator. This arbitration provision shall survive the
            termination of these Terms and Conditions.{" "}
          </div>

          <div className="title2">Liability Disclaimer</div>

          <div className="normal">
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            THREE MONKEYS COMMUNICATION LLC AND/OR ITS SUPPLIERS MAY MAKE
            IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
          </div>

          <div className="normal">
            THREE MONKEYS COMMUNICATION LLC AND/OR ITS SUPPLIERS MAKE NO
            REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
            TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
            SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE.
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
            PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. THREE
            MONKEYS COMMUNICATION LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL
            WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
            WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </div>
          <div className="normal">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            THREE MONKEYS COMMUNICATION LLC AND/OR ITS SUPPLIERS BE LIABLE FOR
            ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
            DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
            DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY
            WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE
            DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
            PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION,
            SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH
            THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER
            BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
            EVEN IF THREE MONKEYS COMMUNICATION LLC OR ANY OF ITS SUPPLIERS HAS
            BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
            STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
            PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          </div>

          <div className="title2">Termination/Access Restriction</div>

          <div className="normal">
            3MC reserves the right, in its sole discretion, to terminate your
            access to the Site and the related services or any portion thereof
            at any time, without notice. To the maximum extent permitted by law,
            this agreement is governed by the laws of the State of Maryland and
            you hereby consent to the exclusive jurisdiction and venue of courts
            in Maryland in all disputes arising out of or relating to the use of
            the Site. Use of the Site is unauthorized in any jurisdiction that
            does not give effect to all provisions of these Terms, including,
            without limitation, this section.
          </div>

          <div className="normal">
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and 3MC as a result of this
            agreement or use of the Site. 3MC's performance of this agreement is
            subject to existing laws and legal process, and nothing contained in
            this agreement is in derogation of 3MC's right to comply with
            governmental, court and law enforcement requests or requirements
            relating to your use of the Site or information provided to or
            gathered by 3MC with respect to such use. If any part of this
            agreement is determined to be invalid or unenforceable pursuant to
            applicable law including, but not limited to, the warranty
            disclaimers and liability limitations set forth above, then the
            invalid or unenforceable provision will be deemed superseded by a
            valid, enforceable provision that most closely matches the intent of
            the original provision and the remainder of the agreement shall
            continue in effect.{" "}
          </div>

          <div className="normal">
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and 3MC with respect to the Site
            and it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and
            3MC with respect to the Site. A printed version of this agreement
            and of any notice given in electronic form shall be admissible in
            judicial or administrative proceedings based upon or relating to
            this agreement to the same extent and subject to the same conditions
            as other business documents and records originally generated and
            maintained in printed form. It is the express wish to the parties
            that this agreement and all related documents be written in English.
          </div>

          <div className="title2">Changes to Terms</div>

          <div className="normal">
            3MC reserves the right, in its sole discretion, to change the Terms
            under which{" "}
            <a href="https://www.3mcomm.com" target="_blank">
              {" "}
              https://www.3mcomm.com.
            </a>{" "}
            is offered. The most current version of the Terms will supersede all
            previous versions. 3MC encourages you to periodically review the
            Terms to stay informed of our updates.{" "}
          </div>

          <div className="title2">Contact Information</div>

          <div className="normal">
            3MC welcomes your questions or comments regarding this Statement of
            Privacy. If you believe that 3MC has not adhered to this Statement,
            please contact 3MC at:
          </div>
          <div className="normal">Three Monkeys Communication LLC</div>
          <div className="normal">
            Email Address: <a href="">support@3mcomm.com</a>
          </div>
          {/* <div className="normal">Telephone number: 240-241-7317</div> */}
          <div className="normal">Effective as of March 22, 2021</div>
        </div>
      </div>
    );
  }
}

export default TermsofUse;
