import React, { Component } from "react";
import Video from "../Youtube/Video";
import Transcripts from "../Youtube/Transcripts";
import SeeMore from "./SeeMore";

import kp from "../images/services/01 Keynote.png";
import ct from "../images/services/02 Training.png";
import pc from "../images/services/03 One on one.png";
import ac from "../images/services/04 consulting.png";
import ic from "../images/services/06 Iwazaru.png";
import mm from "../images/services/05 Mizaru.png";
require("dotenv").config();
const KeynoteText = [
  "Motivational keynote presentations presented from a DeafBlind person's perspective. We  provide several presentations, such as:",
  <ul>
    {/* <br /> */}
    <li key={0}>
      <b>DeafBlind 101</b>: Learn what it is like to have a hearing loss or
      vision loss, and understand the culture of Deaf, Blind, and DeafBlind.
      This presentation is full of hands-on activities that show the challenges
      Deafblind people face daily. The activities will include a "hearing test",
      a blindfold activity, and a short video on how to interact with service
      animals. The entire presentation is 60 minutes, including 30 minutes of
      Q&A. Suitable for audiences of students, teachers, parents, caregivers,
      and specialists.
    </li>
    <li key={1}>
      <b>DeafBlind on the Move</b>: Learn about DeafBlind life through stories
      and photos. This presentation is for anyone who is looking for a
      motivational experience. Gather tips on overcoming the challenges of going
      to college, finding a job, living independently, and traveling with a
      service animal. Total presentation is 60 minutes, including 30 minutes of
      Q&A. Suitable for students, teachers, parents, caregivers, and
      specialists.
    </li>
    <li key={2}>
      <b>Handling Everyday Challenges as a Disabled Entrepreneur</b>: Sharing
      the story of establishing a business and overcoming the obstacles as a
      disabled entrepreneur. Total presentation is 60 minutes, including 30
      minutes of Q&A. Suitable for students, teachers, parents, caregivers, and
      specialists. We offer customized presentations that can be designed to
      suit your company or organization's needs. Please contact us for more
      information.
    </li>
  </ul>,
  <a href=" https://www.youtube.com/watch?v=DFczTZXJakI"> Link to the video</a>,
  <p></p>,
];

const CorporateText = [
  "The various training services we offer are:",
  <ul>
    {/* <br /> */}
    <li key={0}>
      <b>Accessibility Training</b>: What is accessibility, why is it important,
      and how can you create an accessible environment? Receive specialized
      training on how to work with, and provide accessible resources and
      services for people with disabilities. We will reveal why this is
      important and how you can save money, time and trouble by doing the work
      in-house. The training is 4 hours. Suitable for business audiences.
    </li>
    <li key={1}>
      <b>ProTactile (PT) Training</b>: ProTactile is a language/method of
      communication that conveys facial and body expression into touch signals
      since the DeafBlind user cannot see these expressions and movements. PT
      Training will allow you to understand how PT works and how to communicate
      effectively with DeafBlind individuals. The training is 8 hours. Suitable
      for business, students, teachers, parents, caregivers, and specialists.
    </li>
    <li key={2}>
      <b>Assistive Technology (AT) Training</b>: AT training will outline
      various technical products and methods available and provide hands-on
      training for the specific AT your organization plans to utilize. The
      training is 2 hours. Suitable for business, students, teachers, parents,
      caregivers, and specialists.
    </li>
    <li key={3}>
      <b>Diversity and Inclusion in the Workplace</b>: We talk about how you can
      recruit, retain and include more people with disabilities in your
      workplace. Discover what you need to know and how to make it a productive,
      enjoyable working environment for everyone. Total training time is 2
      hours. Suitable for business.{" "}
    </li>
    <li key={4}>
      <b>Support Service Provider (SSP)</b>: This training starts with
      discussing the difference between SSP and interpreter services. We then
      focus on how to become a provider and advocate to the deafblind community
      by becoming a sighted guide, providing visual information, assisting with
      paperwork and personal shopping. There are several hands-on activities to
      help you prepare for real world interaction. The training is 8 hours.
      Suitable for business, students, teachers, parents, caregivers, and
      specialists. We offer customized training that can be designed to suit
      your company or organization's needs. Please contact us for more
      information.{" "}
    </li>
  </ul>,
];

const PersonalCoachingText = [
  "One-on-one mentoring services to assist individuals in creating development plans. Contact us to discuss one or more of the following:",
  <ul>
    {/* <br /> */}
    <li key={0}>
      <b>Transitioning</b>: Coaching during your transition from school to
      college life, college to work life, or additional skills for new life
      challenges.
    </li>
    <li key={1}>
      <b>Employment</b>: Coaching in career planning, job searching, interview
      skills, and workplace accommodations.
    </li>
    <li key={2}>
      <b>Life Skills</b>: Coaching on a variety of practical independent living
      skills such as: daily living skills, transportation and mobility, creative
      problem solving, and self acceptance/confidence.{" "}
    </li>
    <li key={3}>
      <b>Communication Skills</b>: Coaching individuals, interpreters, SSPs and
      specialists in using ASL and Pro-tactile to better communicate with the
      deafblind community.{" "}
    </li>
    <li key={4}>
      <b>Advocacy Skills</b>: Learn your rights and responsibilities, improve
      your presentation skills, locate/discover appropriate resources, and
      develop a circle of support.
    </li>
    <li key={5}>
      <b>Community Support Skills</b>: Mentoring in co-navigation, empowering
      the deafblind community and allyship.
    </li>
  </ul>,
];

const AccessibilityText = [
  "We know creating a website or product is hard, and making your website and product accessible to everyone is even harder. That is why we want to help! As a Certified Professional in Accessibility Core Competencies (CPACC), we can help you test and convert your website to be accessible for consumers with disabilities.",
  <p></p>,
  <p>
    We provide accessibility testing and consulting on websites, web
    applications, consumer products, and services in numerous industries. We are
    experienced in testing websites, providing scorecards, and reports based on
    our thorough evaluation. We will collect information and help you develop a
    plan to improve the accessibility of your products and services. We also
    test accessibility with Braille and voiceover features to ensure your
    customers have a great user experience.{" "}
  </p>,
];

const IwazaruText = [
  "We have our own YouTube channel! We are excited to share our travel and first-hand experiences from the perspective of people with disabilities. We look into their lives and share the challenges and successes of how they overcome their limitations. We want to capture moments that make our disabled community stronger every single day.",
  <p></p>,
  <p>
    We update the Iwazaru channel regularly, so we encourage you to go to our
    channel and click like, share, and subscribe. "
  </p>,
  <p></p>,
  <a href="https://www.youtube.com/channel/UCaHymjlliFHCFG3qkD4blng">
    Link to the YouTube Channel{" "}
  </a>,
  <p></p>,
];

const MizaruText = [
  "Designed by people with disabilities, Mizaru connects people who need support to people who can assist. Regardless of where you are, you can search for qualified support service providers, communication facilitators, and product delivery whenever needed. Using Mizaru, we want to empower people with disabilities to explore this world with reliable support in a broader network. That is why our vision for this app is to bring the world closer to people.",
  <p></p>,
  <p>We are here to assist with:</p>,
  <ul>
    {/* <br /> */}
    <li key={1}>
      <b>Communication Facilitators (CF)</b>: If you are having trouble using
      the phone, videophone, or relay services, we have a solution for you.
      Request a CF and a person will come and assist you with making phone
      calls. Some states are funded by the government. This service depends on
      the demand and resources available in the area of the request.{" "}
    </li>
    <li key={0}>
      <b>Support Service Providers (SSP)/Co-Navigator</b>: You can submit a
      request for a ride, sighted guide, or personal shopper by putting in a
      Support Service Provider (SSP) request through the Mizaru app which is
      linked at the top of the home page and we will see who is available in the
      area you need the services to find a good match. If we don’t have any SSP
      in the area, we can train them and get them added. We can grow based on
      the demand in specific areas.{" "}
    </li>
    <li key={2}>
      <b>Product Delivery (PD)</b>: Stuck at home, no ride, or not feeling good
      enough to go out of the house but need groceries or essential products and
      not sure how to buy online or the store’s website isn’t accessible? No
      problem, we can help. We have people who are willing to assist by taking
      your order, going shopping for you, and delivering the items to your door.
      We will scan the receipt to ensure you are paying for what you asked for.{" "}
    </li>
  </ul>,
  <a href={process.env.REACT_APP_MIZARU_LINK} target="_blank" rel="Mizaru app">
    Link to the Mizaru App{" "}
  </a>,
  <p></p>,
];

export default class Service extends Component {
  render() {
    return (
      <div className="container services section-padding">
        <h2 className="title1 services-title titlePadding">
          <a name="services" tabIndex="0">
            Services & Products
          </a>
        </h2>
        <div className="services-video section2Padding">
          <Video id="xjGlikSE0yk" title="3mc service video" />
          <div className="services-video-text">
            <Transcripts id="xjGlikSE0yk" />
          </div>
        </div>
        <br />
        <br />
        <div className="row services-content">
          <div className="col-3 services-content-icon">
            <img
              src={kp}
              alt="Keynote Presentation-logo"
              className="services-content-icon--img"
              tabIndex="0"
            />
          </div>
          <div className="col-9 services-content-text">
            <h3 className="title2" tabIndex="0">
              Presentations
            </h3>
            <div className="normal" tabIndex="-1">
              <SeeMore text={KeynoteText} />
            </div>
          </div>
        </div>
        <div className="row services-content">
          <div className="col-3 services-content-icon">
            <img
              src={ct}
              alt="Corporate Training-logo"
              className="services-content-icon--img"
              tabIndex="0"
            />
          </div>
          <div className="col-9 services-content-text">
            <div className="title2" tabIndex="0">
              Training
            </div>
            <div className="normal" tabIndex="-1">
              <SeeMore text={CorporateText} />
            </div>
          </div>
        </div>
        <div className="row services-content">
          <div className="col-3 services-content-icon">
            <img
              src={pc}
              alt="Personal Coaching icon"
              className="services-content-icon--img"
              tabIndex="0"
            />
          </div>
          <div className="col-9 services-content-text">
            <h3 className="title2" tabIndex="0">
              Mentoring
            </h3>
            <div className="normal" tabIndex="-1">
              <SeeMore text={PersonalCoachingText} />
            </div>
          </div>
        </div>
        <div className="row services-content">
          <div className="col-3 services-content-icon">
            <img
              src={ac}
              alt="Accessibility Consulting icon"
              className="services-content-icon--img"
              tabIndex="0"
            />
          </div>
          <div className="col-9 services-content-text">
            <h3 className="title2" tabIndex="0">
              Consulting
            </h3>
            <div className="normal" tabIndex="-1">
              <SeeMore text={AccessibilityText} />
            </div>
          </div>
        </div>
        <div className="row services-content">
          <div className="col-3 services-content-icon">
            <img
              src={ic}
              alt="Iwazaru channel icon"
              className="services-content-icon--img"
              tabIndex="0"
            />
          </div>
          <div className="col-9 services-content-text">
            <div className="title2" tabIndex="0">
              Iwazaru channel
            </div>
            <div className="normal" tabIndex="-1">
              <SeeMore text={IwazaruText} />
            </div>
          </div>
        </div>
        <div className="row services-content">
          <div className="col-3 services-content-icon">
            <img
              src={mm}
              alt="My Mizaru icon"
              className="services-content-icon--img"
              tabIndex="0"
            />
          </div>
          <div className="col-9 services-content-text">
            <div className="title2" tabIndex="0">
              Mizaru app
            </div>
            <div className="normal" tabIndex="-1">
              <SeeMore text={MizaruText} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
