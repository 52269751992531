import React, { Component } from "react";
import Video from "../Youtube/Video";
import Transcripts from "../Youtube/Transcripts";

import jc from "../images/team/JC profile pic.jpg";
import pg from "../images/team/Peggy profile pic.jpg";
import jh from "../images/team/JL profile pic.jpg";
import ben from "../images/team/Ben profile pic.jpg";
import jon from "../images/team/Jon profile pic.png";
import eric from "../images/team/Eric Soen.jpg";

import hd from "../images/summer_team/Haokun Dong.jpeg";
import lg from "../images/summer_team/Linzhou gu.jpeg";
import tz from "../images/summer_team/Teng Zhang.jpeg";
import xz from "../images/summer_team/Xurui Zhang.jpg";
import zs from "../images/summer_team/Zheng Sang final";

export default class Team extends Component {
  render() {
    return (
      <div className="container team section-padding">
        <h2 className="title1 team-title titlePadding">
          <a name="team" tabIndex="0">
            Meet Our Team
          </a>
        </h2>
        <div className="team-video section2Padding">
          <Video id="EaQ6pI9tKgg" title="3mc mizaru team video" />
          <div className="team-video-text">
            <Transcripts id="EaQ6pI9tKgg" />
          </div>
        </div>
        <div className="team-main">
          <div className="row">
            <div className="col-6">
              <img
                className="team-main-profile"
                src={jc}
                alt="Jason A. Corning"
                tabIndex="0"
              />
              <div className="team-main-name" tabIndex="0">
                Jason A. Corning
              </div>
              <div className="team-main-intro" tabIndex="0">
                Founder & CEO
              </div>
            </div>
            <div className="col-6">
              <img
                className="team-main-profile"
                src={jh}
                alt="Jason H. Corning"
                tabIndex="0"
              />
              <div className="team-main-name" tabIndex="0">
                Jason H. Corning
              </div>
              <div className="team-main-intro" tabIndex="0">
                Co-Founder & Chief Product Officer
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <img
                className="team-main-profile"
                src={pg}
                alt="Peggy S. Corning"
                tabIndex="0"
              />
              <div className="team-main-name" tabIndex="0">
                Peggy S. Corning
              </div>
              <div className="team-main-intro" tabIndex="0">
                HR/Accounting
              </div>
            </div>

            <div className="col-6">
              <img
                className="team-main-profile"
                src={ben}
                alt="Benjamin J. Belisle"
                tabIndex="0"
              />
              <div className="team-main-name" tabIndex="0">
                Benjamin J. Belisle
              </div>
              <div className="team-main-intro" tabIndex="0">
                Chief Information Officer
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <img
                className="team-main-profile"
                src={jon}
                alt="Chunliang Zhang (Jon)"
                tabIndex="0"
              />
              <div className="team-main-name" tabIndex="0">
                Chunliang Zhang (Jon)
              </div>
              <div className="team-main-intro" tabIndex="0">
                Video Editor
              </div>
            </div>
            <div className="col-6">
              <img
                className="team-main-profile"
                src={eric}
                alt="Jiacheng Sun (Eric)"
                tabIndex="0"
              />
              <div className="team-main-name" tabIndex="0">
                Jiacheng Sun (Eric)
              </div>
              <div className="team-main-intro" tabIndex="0">
                Graphic Designer
              </div>
            </div>
          </div>
        </div>
        {/*<div className="team-special" tabIndex="0">Special thanks to our 2020 Summer development team</div>*/}
        {/*<div className="team-dev">*/}
        {/*    <div className="team-dev-wrapper">*/}
        {/*        <img className="team-dev-profile" src={hd} alt="Haokun Dong" tabIndex="0"/>*/}
        {/*        <div className="team-dev-name" tabIndex="0">Haokun Dong</div>*/}
        {/*        <div className="team-dev-intro" tabIndex="0">A graduate student at Columbia University</div>*/}
        {/*    </div>*/}
        {/*    <div className="team-dev-wrapper">*/}
        {/*        <img className="team-dev-profile" src={lg} alt="Linzhou Gu" tabIndex="0"/>*/}
        {/*        <div className="team-dev-name" tabIndex="0">Linzhou Gu</div>*/}
        {/*        <div className="team-dev-intro" tabIndex="0">A graduate student at Northeastern University</div>*/}
        {/*    </div>*/}
        {/*    <div className="team-dev-wrapper">*/}
        {/*        <img className="team-dev-profile" src={tz} alt="Teng Zhang" tabIndex="0"/>*/}
        {/*        <div className="team-dev-name" tabIndex="0">Teng Zhang</div>*/}
        {/*        <div className="team-dev-intro" tabIndex="0">A graduate student at Northeastern University</div>*/}
        {/*    </div>*/}
        {/*    <div className="team-dev-wrapper">*/}
        {/*        <img className="team-dev-profile" src={xz} alt="Xurui Zhang" tabIndex="0"/>*/}
        {/*        <div className="team-dev-name" tabIndex="0">Xurui Zhang</div>*/}
        {/*        <div className="team-dev-intro" tabIndex="0">A graduate student at Rice University</div>*/}
        {/*    </div>*/}
        {/*    <div className="team-dev-wrapper">*/}
        {/*        <img className="team-dev-profile" src={zs} alt="Zheng Sang" tabIndex="0"/>*/}
        {/*        <div className="team-dev-name" tabIndex="0">Zheng Sang</div>*/}
        {/*        <div className="team-dev-intro" tabIndex="0">A graduate student at Rensselaer Polytechnic Institute</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    );
  }
}
