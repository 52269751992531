import React, { Component } from "react";
export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = { url: "https://www.youtube.com/embed/" + this.props.id };
  }
  render() {
    return (
      <div>
        <div>
          <iframe
            title={this.props.title}
            src={this.state.url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  }
}
