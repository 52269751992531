import React, { Component } from "react";
import Video from "../Youtube/Video";
import Transcripts from "../Youtube/Transcripts";

export default class Intro extends Component {
  render() {
    return (
      <div className="container intro">
        <div className="row">
          <div className="col-12 col-md-6 intro-content">
            <h2 className="title1 services-title titlePadding">
              <a name="aboutus" tabIndex="0">
                About Us
              </a>
            </h2>
            <div className="intro-content-text">
              <div className="title2" tabIndex="0">
                Our Mission
              </div>
              <p className="normal" tabIndex="0">
                Breaking down communication barriers for people with
                disabilities
              </p>
              <div className="title2" tabIndex="0">
                What we do
              </div>
              <div className="normal" tabIndex="0">
                Established in 2018, Three Monkeys Communication LLC (3MC) is
                passionate about providing information and insight to
                educational institutions, companies, and organizations about
                people who have low vision, are blind, deaf, or DeafBlind.
              </div>
              <br />
              <div className="normal" tabIndex="0">
                Three Monkeys Communication LLC (3MC) uses the story of three
                wise monkeys as inspiration as we provide services. The three
                wise monkeys are a Japanese pictorial maxim, embodying the
                proverbial principle "see no evil, hear no evil, speak no evil".
                The three monkeys are Mizaru, covering his eyes, who sees no
                evil; Kikazaru, covering his ears, who hears no evil and
                Iwazaru, covering his mouth, who speaks no evil. The three wise
                monkeys parallel people who cannot see, hear, or speak;
                therefore we embrace them as we advance 3MC's mission to break
                down communication barriers for people with disabilities.
              </div>
              <br />
              <div className="normal" tabIndex="0">
                We offer four types of services including presentations,
                training, mentoring, and consulting. We also have two products,
                including the Mizaru app and the Iwazaru channel.
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 intro-video">
            <Video id="-aaZ8EGDU8w" title="3mc intro video" />
            <div className="intro-video-text">
              <Transcripts id="-aaZ8EGDU8w" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
