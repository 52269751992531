import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer container-fluid">
        <div className="container">
          <div className="footer-link1">
            <Link to="/privacy#privacy-title">
              <span className="privacy">Privacy Policy</span>
            </Link>
            <Link to="/terms_of_use#terms-title">
              <span className="terms">Terms of Use</span>
            </Link>
          </div>
          <div className="footer-link2" tabIndex="0">
            Follow Us
          </div>
          <div className="footer-icons">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/35502774"
              title="Follow us on Linkedin"
            >
              <i className="fab fa-linkedin-in">
                <div className="footer-icons-text">Linkedin</div>
              </i>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/threemonkeyscommication"
              title="Follow us on Facebook"
            >
              <i className="fa fa-facebook-f">
                <div className="footer-icons-text">Facebook</div>
              </i>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCaHymjlliFHCFG3qkD4blng"
              title="Follow us on Youtube"
            >
              <i className="fab fa-youtube">
                <div className="footer-icons-text">Youtube</div>
              </i>
            </a>
          </div>
          <div className="copyright" tabIndex="0">
            © 2021 Three Monkeys Communication LLC All rights reserved
          </div>
        </div>
      </div>
    );
  }
}
