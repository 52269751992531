import React from "react";

export default class Transcripts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            url: "https://video.google.com/timedtext?lang=en&v=" + this.props.id
        }
        this.xhr = null
    }


    componentDidMount() {
        const decodeHTML = (function () {
            let el = document.createElement('div');

            function __decode(str) {
                if (str && typeof str === 'string') {
                    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')
                        .replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                    el.innerHTML = str;
                    str = el.textContent;
                    el.textContent = '';
                }
                return str;
            }

            removeElement(el); // Clean-up
            return __decode;
        })();

        function removeElement(el) {
            el && el.parentNode && el.parentNode.removeChild(el);
        }

        function parseTranscriptAsJSON(xml) {
            return [].slice.call(xml.querySelectorAll('transcript text'))
                .map(text => ({
                    // start : formatTime(Math.floor(text.getAttribute('start'))),
                    // dur : formatTime(Math.floor(text.getAttribute('dur'))),
                    text: decodeHTML(text.textContent).replace(/\s+/g, ' ')
                }));
        }

        // function formatTime(seconds) {
        //     let date = new Date(null);
        //     date.setSeconds(seconds);
        //     return date.toISOString().substr(11, 8);
        // }

        function jsonToCsv(json, options) {
            options = Object.assign({
                includeHeader: false,
                delimiter: ',',
                ignoreKeys: []
            }, options || {});
            let keys = Object.keys(json[0]).filter(key => options.ignoreKeys.indexOf(key) === -1);
            let lines = [];
            if (options.includeHeader) { lines.push(keys.join(options.delimiter)); }
            return lines.concat(json
                .map(entry => keys.map(key => entry[key]).join(options.delimiter)))
                .join('\n');
        }



        this.xhr = new XMLHttpRequest()
        this.xhr.open('POST', this.state.url, true);
        this.xhr.responseType = 'document';
        this.xhr.onload = () => {
            if (this.xhr.status >= 200 && this.xhr.status < 400) {
                // this.setState({ data: jsonToCsv(parseTranscriptAsJSON(this.xhr.response)) })

                let json = parseTranscriptAsJSON(this.xhr.response)
                let arr = []
                for (var item of json) {
                    arr.push(item['text'])
                }
                this.setState({ data: arr })
            }

        };
        this.xhr.send();
    }

    render() {
        return (
            <div className='transcript-wrapper'>
                <div className="transcript-text" tabIndex="0" aria-label="Here is the transcript section">
                    <div className="hidden">
                        <a href={`#skip${this.props.id}`} aria-label="Here is the start of transcript, press tab to start reading, press enter to skip the transcript"></a>
                    </div>
                    <div>{this.state.data.map((item, index) => <p key={index} tabIndex="0">{item}</p>)}</div>
                    <div className="hidden">
                        <a tabIndex="0" name={`skip${this.props.id}`} aria-label="you have reached the end of transcript, tab again to the next section"></a>
                    </div>
                </div>
            </div>


        );
    }
}
